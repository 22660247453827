import React, { useState, useEffect, useRef } from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { gsap } from 'gsap';

import logo from '../assets/berc-wh.png';
import logo1 from '../assets/berc-logo.jpeg';

import Partner from './Partner';
import ScrollToTopButton from '../components/ScrollToTopButton';

// Tools Images
import bpdImg1 from '../assets/toolsImages/bpd-img1.jpg';
import bpdImg2 from '../assets/toolsImages/bpd-img2.jpg';
import bpdImg3 from '../assets/toolsImages/bpd-img3.jpg';
import bpdImg4 from '../assets/toolsImages/bpd-img4.jpg';
import lcaImg1 from '../assets/toolsImages/lca-img1.jpg';
import lcaImg2 from '../assets/toolsImages/lca-img2.jpg';
import lcaImg3 from '../assets/toolsImages/lca-img3.jpg';
import lcaImg4 from '../assets/toolsImages/lca-img4.jpg';
import lcaImg5 from '../assets/toolsImages/lca-img5.jpg';
import lcaImg6 from '../assets/toolsImages/lca-img6.jpg';
import lcaImg7 from '../assets/toolsImages/lca-img7.jpg';
import rlfImg1 from '../assets/toolsImages/rlf1.jpeg';
import rlfImg2 from '../assets/toolsImages/rlf2.jpeg';
import rlfImg3 from '../assets/toolsImages/rlf3.jpeg';
import comImg3 from '../assets/toolsImages/com-img3.png';
import comImg4 from '../assets/toolsImages/com-img4.png';
import comImg5 from '../assets/toolsImages/com-img5.png';
import comImg6 from '../assets/toolsImages/com-img6.png';
import comImg7 from '../assets/toolsImages/com-img7.png';
import comImg8 from '../assets/toolsImages/com-img8.png';
import comImg10 from '../assets/toolsImages/com-img10.png';
import UImg from '../assets/toolsImages/uvalue-img.jpg';
import UVimg from '../assets/toolsImages/uvcalc.jpg';

// Stakeholders Images
import lums from '../assets/lums.jpeg';
import cda from '../assets/cda.jpeg';
import pha from '../assets/pha.jpeg';
import pda from '../assets/pda.jpeg';
import neeca from '../assets/neeca.jpeg';
import giz from '../assets/giz.jpeg';

// SDGs Images
import sdg7 from '../assets/sdg7.jpeg';
import sdg11 from '../assets/sdg11.jpeg';
import sdg12 from '../assets/sdg12.jpeg';
import sdg13 from '../assets/sdg13.jpeg';

const tools = [
  {
    name: 'Life Cycle Assessment (LCA)',
    description:
      'This tool calculates the carbon emissions along with the estimated cost of the construction project based on the specific details of the construction project entered by the user.',
    link: 'https://lca.berc.pk/',
    images: [lcaImg1, lcaImg2, lcaImg3, lcaImg4, lcaImg5, lcaImg6, lcaImg7],
  },
  {
    name: 'Residential Load Factor (RLF)',
    description: 'An evaluation tool for assessing residential energy consumption patterns.',
    link: 'https://rlf.berc.pk/login.php',
    images: [rlfImg1, rlfImg2, rlfImg3],
  },
  {
    name: 'Building Performance Design (BPD)',
    description: 'A tool for designing energy-efficient building performance strategies.',
    link: 'https://bpd.berc.pk/',
    images: [bpdImg1, bpdImg2, bpdImg3, bpdImg4],
  },
  {
    name: 'ECBC Compliance Tool',
    description: 'A tool for ensuring compliance with the Energy Conservation Building Code (ECBC).',
    link: 'https://compliance.berc.pk',
    images: [comImg3, comImg4, comImg5, comImg6, comImg7, comImg8, comImg10],
  },
  {
    name: 'U-Values Finder',
    description: 'A tool for finding the U-values of various building materials.',
    link: 'https://berc.pk/uvalues/',
    images: [UImg],
  },
  {
    name: 'U-Value Calculator',
    description: 'A tool for finding the U-values of various building materials.',
    link: 'https://berc.pk/uvcalculator/',
    images: [UVimg],
  },
];

const aboutUsItems = [
  {
    name: 'Mission Statement',
    description:
      'BERC accelerates a sustainable built environment in Pakistan through innovative R&D, empowering building stakeholders with expertise, and advocating for energy-efficient policies',
  },
  {
    name: 'Our Expertise',
    description: '',
    stats: [
      { label: 'Years of Experience', value: '12' },
      { label: 'People Trained', value: '900+' },
      { label: 'Positive Reviews', value: '300+' },
      { label: 'International Clients', value: '2+' },
    ],
  },
  {
    name: 'Our Stakeholders',
    description: '',
    images: [giz, neeca, cda, pda, pha, lums],
  },
  {
    name: 'How We Work',
    description: 'This link is currently under development. Please check back later!',
  },
  {
    name: 'Guiding Principle',
    description: (
      <>
        <p className="font-bold">Guiding Principle 1: Environmental Stewardship</p>
        <p>
          At BERC, we are committed to caring for the planet and its people. We believe in building a
          sustainable Pakistan through informed and gradual actions. Our interventions, large or
          small, are always aimed at reducing greenhouse gas emissions and carbon footprints.
        </p>
        <p className="font-bold mt-4">Guiding Principle 2: Just Transition</p>
        <p>
          We believe in ensuring a right to a clean environment and comfortable living spaces for
          everyone, regardless of socioeconomic background. Our focus on sustainability extends
          beyond urban areas to include rural communities, women, and marginalized groups who are most
          vulnerable to climate change. BERC is dedicated to creating sustainable and resilient
          infrastructure for all.
        </p>
        <p className="font-bold mt-4">Guiding Principle 3: Innovation and Local Solutions</p>
        <p>
          We are driven by the belief that the best solutions to our problems often come from those
          closest to them. Therefore, we prioritize homegrown & local solutions in our efforts to
          address Pakistan's energy challenges. Our development of an indigenous building energy
          rating system is a testament to our commitment to local innovation. We are continuously
          training our youth in advanced building energy modeling techniques, equipping them with the
          tools to create tailored solutions for Pakistan's unique needs. By investing in local
          expertise and fostering a culture of innovation, we aim to empower Pakistanis to drive
          sustainable change and combat the energy and climate crises.
        </p>
      </>
    ),
  },

  {
    name: 'Supported SDGs',
    description: 'Description for Supported SDGs.',
    images: [sdg7, sdg11, sdg12, sdg13],
  },
];

const ourImpactItems = [
  {
    name: 'Impact Stories',
    description: 'This link is currently under development. Please check back later!',
  },
  {
    name: 'Feedback',
    description: 'Watch our users share their experiences and feedback.',
    videos: [
      'https://www.youtube.com/embed/dH7NBBnCUJw?si=f28lfghv3v8MrAha',
      'https://www.youtube.com/embed/LtYXMcWzkuY?si=Cr0JjXuZlNR5iSnv',
      'https://www.youtube.com/embed/pcvTdpB-ItE?si=Mw_TMFL60kyi_vng',
    ],
  },
];

const Navbar = () => {
  const [open, setOpen] = useState(false);
  const [activeTool, setActiveTool] = useState(null);
  const [activeAboutUsItem, setActiveAboutUsItem] = useState(null);
  const [activeOurImpactItem, setActiveOurImpactItem] = useState(null);

  const [navbarScrolled, setNavbarScrolled] = useState(false);

  // State variables to manage menu open states
  const [isAboutUsMenuOpen, setIsAboutUsMenuOpen] = useState(false);
  const [isToolsMenuOpen, setIsToolsMenuOpen] = useState(false);
  const [isOurImpactMenuOpen, setIsOurImpactMenuOpen] = useState(false);

  // Refs for detecting clicks outside
  const aboutUsMenuRef = useRef(null);
  const toolsMenuRef = useRef(null);
  const ourImpactMenuRef = useRef(null);

  useEffect(() => {
    // GSAP animation for extended navbar
    if (isAboutUsMenuOpen || isToolsMenuOpen || isOurImpactMenuOpen) {
      gsap.fromTo(
        '.extended-navbar',
        { opacity: 0, y: -20, scale: 0.95 },
        { opacity: 1, y: 0, scale: 1, duration: 0.5, ease: 'power3.out' }
      );
    }
  }, [isAboutUsMenuOpen, isToolsMenuOpen, isOurImpactMenuOpen]);

  useEffect(() => {
    const handleScroll = () => {
      setNavbarScrolled(window.scrollY > 0);
    };
    window.addEventListener('scroll', handleScroll);

    // Clean up
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  // Close menus when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        isAboutUsMenuOpen &&
        aboutUsMenuRef.current &&
        !aboutUsMenuRef.current.contains(event.target)
      ) {
        setIsAboutUsMenuOpen(false);
        setActiveAboutUsItem(null);
      }
      if (
        isToolsMenuOpen &&
        toolsMenuRef.current &&
        !toolsMenuRef.current.contains(event.target)
      ) {
        setIsToolsMenuOpen(false);
        setActiveTool(null);
      }
      if (
        isOurImpactMenuOpen &&
        ourImpactMenuRef.current &&
        !ourImpactMenuRef.current.contains(event.target)
      ) {
        setIsOurImpactMenuOpen(false);
        setActiveOurImpactItem(null);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isAboutUsMenuOpen, isToolsMenuOpen, isOurImpactMenuOpen]);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  // Toggle menu functions
  const toggleAboutUsMenu = () => {
    setIsAboutUsMenuOpen(!isAboutUsMenuOpen);
    setIsToolsMenuOpen(false);
    setIsOurImpactMenuOpen(false);
  };

  const toggleToolsMenu = () => {
    setIsToolsMenuOpen(!isToolsMenuOpen);
    setIsAboutUsMenuOpen(false);
    setIsOurImpactMenuOpen(false);
  };

  const toggleOurImpactMenu = () => {
    setIsOurImpactMenuOpen(!isOurImpactMenuOpen);
    setIsAboutUsMenuOpen(false);
    setIsToolsMenuOpen(false);
  };

  return (
    <div>
      <nav
        className={`fixed top-0 left-0 w-full shadow-md z-20 transition-all duration-300 ${navbarScrolled ? 'bg-[#fafafa]' : 'bg-transparent'
          }`}
        style={{ height: '80px' }}
      >
        <div
          className="flex items-center justify-between px-4 lg:px-12 transition-all duration-300"
          style={{ width: '100%' }}
        >
          {/* Left Side Logo */}
          <img
            src={navbarScrolled ? logo1 : logo}
            alt="Logo"
            className="h-24 w-40 object-contain"
          />

          {/* Right Side Links */}
          <div className="flex items-center space-x-6 flex-grow justify-end">
            <ul className="hidden md:flex space-x-6">
              <li className="transform hover:scale-110 transition-transform duration-300">
                <a
                  href="#home"
                  className={`text-lg font-bold ${navbarScrolled
                    ? 'text-gray-700 hover:text-blue-500'
                    : 'text-white hover:text-gray-300'
                    }`}
                >
                  Home
                </a>
              </li>
              <li className="relative transform hover:scale-110 transition-transform duration-300">
                <button
                  className={`flex items-center text-lg font-bold focus:outline-none ${navbarScrolled
                    ? 'text-gray-700 hover:text-blue-500'
                    : 'text-white hover:text-gray-300'
                    }`}
                  onClick={toggleAboutUsMenu}
                >
                  About Us
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={2}
                    stroke="currentColor"
                    className={`w-5 h-5 ml-1 transform transition-transform duration-200 ${isAboutUsMenuOpen ? 'rotate-180' : ''
                      }`}
                  >
                    <path strokeLinecap="round" strokeLinejoin="round" d="M19 9l-7 7-7-7" />
                  </svg>
                </button>
              </li>
              <li className="transform hover:scale-110 transition-transform duration-300">
                <a
                  href="#services"
                  className={`text-lg font-bold ${navbarScrolled
                    ? 'text-gray-700 hover:text-blue-500'
                    : 'text-white hover:text-gray-300'
                    }`}
                >
                  Our Services
                </a>
              </li>
              <li className="relative transform hover:scale-110 transition-transform duration-300">
                <button
                  className={`flex items-center text-lg font-bold focus:outline-none ${navbarScrolled
                    ? 'text-gray-700 hover:text-blue-500'
                    : 'text-white hover:text-gray-300'
                    }`}
                  onClick={toggleToolsMenu}
                >
                  Tools
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={2}
                    stroke="currentColor"
                    className={`w-5 h-5 ml-1 transform transition-transform duration-200 ${isToolsMenuOpen ? 'rotate-180' : ''
                      }`}
                  >
                    <path strokeLinecap="round" strokeLinejoin="round" d="M19 9l-7 7-7-7" />
                  </svg>
                </button>
              </li>
              <li className="relative transform hover:scale-110 transition-transform duration-300">
                <button
                  className={`flex items-center text-lg font-bold focus:outline-none ${navbarScrolled
                    ? 'text-gray-700 hover:text-blue-500'
                    : 'text-white hover:text-gray-300'
                    }`}
                  onClick={toggleOurImpactMenu}
                >
                  Our Impact
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 24 24"
                    strokeWidth={2}
                    stroke="currentColor"
                    className={`w-5 h-5 ml-1 transform transition-transform duration-200 ${isOurImpactMenuOpen ? 'rotate-180' : ''
                      }`}
                  >
                    <path strokeLinecap="round" strokeLinejoin="round" d="M19 9l-7 7-7-7" />
                  </svg>
                </button>
              </li>
              <li className="transform hover:scale-110 transition-transform duration-300">
                <a
                  href="#news-events"
                  className={`text-lg font-bold ${navbarScrolled
                    ? 'text-gray-700 hover:text-blue-500'
                    : 'text-white hover:text-gray-300'
                    }`}
                >
                  News & Events
                </a>
              </li>
              <li className="transform hover:scale-110 transition-transform duration-300">
                <a
                  href="#team-members"
                  className={`text-lg font-bold ${navbarScrolled
                    ? 'text-gray-700 hover:text-blue-500'
                    : 'text-white hover:text-gray-300'
                    }`}
                >
                  Team
                </a>
              </li>
              <li className="transform hover:scale-110 transition-transform duration-300">
                <a
                  href="#gallery"
                  className={`text-lg font-bold ${navbarScrolled
                    ? 'text-gray-700 hover:text-blue-500'
                    : 'text-white hover:text-gray-300'
                    }`}
                >
                  Gallery
                </a>
              </li>
              <li className="transform hover:scale-110 transition-transform duration-300">
                <a
                  href="#partner"
                  className="text-lg font-medium hover:text-white bg-blue-500 hover:bg-blue-700 text-white py-2 px-4 rounded"
                  onClick={handleOpen}
                >
                  Contact Us
                </a>
              </li>
            </ul>
          </div>
        </div>

        {/* Expanded Navbar showing About Us and descriptions */}
        {isAboutUsMenuOpen && (
          <div
            className={`absolute top-full left-0 right-0 w-screen flex mx-auto bg-gray-200 shadow-lg z-10 extended-navbar`}
            ref={aboutUsMenuRef}
          >
            {/* First div with titles */}
            <div className="w-2/5 p-4">
              <ul className="space-y-4">
                {aboutUsItems.map((item, index) => (
                  <li
                    key={index}
                    onMouseEnter={() => setActiveAboutUsItem(item)}
                    onClick={() => {
                      setIsAboutUsMenuOpen(false);
                      setActiveAboutUsItem(null);
                    }}
                    className="flex justify-center cursor-pointer transition-colors duration-300"
                  >
                    <div className="w-full max-w-xs text-2xl font-bold text-gray-700 hover:text-blue-500 text-left">
                      {item.name}
                    </div>
                  </li>
                ))}
              </ul>
            </div>




            {/* Vertical Line */}
            <div className="w-px bg-gray-300"></div>

            {/* Second div with description */}
            <div className="w-3/5 p-6 flex flex-col items-center justify-start">
              {activeAboutUsItem ? (
                <div className="text-center w-full">
                  {activeAboutUsItem.description && (
                    <p className="mt-2 text-lg mb-4 text-left">{activeAboutUsItem.description}</p>
                  )}

                  {activeAboutUsItem.stats && (
                    <div className="grid grid-cols-2 gap-4 mt-4">
                      {activeAboutUsItem.stats.map((stat, index) => (
                        <div key={index} className="flex flex-col items-center">
                          <p className="text-3xl font-bold text-blue-500">{stat.value}</p>
                          <p className="text-lg text-gray-700">{stat.label}</p>
                        </div>
                      ))}
                    </div>
                  )}
                  {activeAboutUsItem.images && (
                    <div className="grid grid-cols-2 md:grid-cols-3 gap-4">
                      {activeAboutUsItem.images.map((image, index) => (
                        <img
                          key={index}
                          src={image}
                          className="w-32 h-auto mx-auto"
                          alt={`${index + 1}`}
                        />
                      ))}
                    </div>
                  )}
                </div>
              ) : (
                <div className="text-lg text-gray-500 text-center">
                  <p className="font-bold text-2xl mb-4">Learn More About Us</p>
                  <p className="mb-2">Hover over an item to see its description.</p>
                </div>
              )}
            </div>

          </div>
        )}

        {/* Expanded Navbar showing Tools and descriptions */}
        {isToolsMenuOpen && (
          <div
            className="absolute top-full left-0 right-0 w-screen flex mx-auto  bg-gray-200 shadow-lg z-10 extended-navbar"
            ref={toolsMenuRef}
          >
            {/* Left div with tool names - 40% width */}
            <div className="w-2/5 p-4"> {/* 2/5 is 40% */}
              <ul className="space-y-4">
                {tools.map((tool, index) => (
                  <li
                    key={index}
                    onMouseEnter={() => setActiveTool(tool)} // Set activeTool on hover
                    onClick={() => {
                      setIsToolsMenuOpen(false);
                      setActiveTool(null);
                      window.open(tool.link, '_blank'); // Redirect to the tool link in a new tab
                    }}
                    className="flex justify-center cursor-pointer transition-colors duration-300"
                  >
                    <div className="w-full max-w-xs text-2xl font-bold text-gray-700 hover:text-blue-500 text-left">
                      {tool.name}
                    </div>
                  </li>
                ))}
              </ul>
            </div>

            {/* Vertical Line */}
            <div className="w-px bg-gray-300"></div>

            {/* Right div with tool descriptions and carousel - 60% width */}
            <div className="w-3/5 p-6 flex flex-col items-center justify-start"> {/* 3/5 is 60% */}
              {activeTool ? (
                <div className="text-center w-full">
                  <p className="mt-2 text-lg mb-4">{activeTool.description}</p>
                  <a
                    href={activeTool.link}
                    className="text-blue-500 hover:text-blue-700 font-medium mb-4 inline-block"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {activeTool.link}
                  </a>
                  <Carousel showThumbs={false}>
                    {activeTool.images.map((image, i) => (
                      <div key={i}>
                        <img src={image} alt={`Slide ${i}`} />
                      </div>
                    ))}
                  </Carousel>
                </div>
              ) : (
                <div className="text-lg text-gray-500 text-center">
                  <p className="font-bold text-2xl mb-4">Explore Our Cutting-Edge Tools</p>
                  <p className="mb-2">Hover over a tool to see its description and detailed images.</p>
                </div>
              )}
            </div>
          </div>

        )}

        {/* Expanded Navbar showing Our Impact and descriptions */}
        {isOurImpactMenuOpen && (
          <div
            className={`absolute top-full left-0 right-0 w-screen flex mx-auto  bg-gray-200 shadow-lg z-10 extended-navbar`}
            ref={ourImpactMenuRef}
          >
            <div className="w-1/2 p-4">
              <ul className="space-y-4">
                {ourImpactItems.map((item, index) => (
                  <li
                    key={index}
                    onMouseEnter={() => setActiveOurImpactItem(item)}  // Set activeOurImpactItem on hover
                    onClick={() => {
                      setIsOurImpactMenuOpen(false);
                      setActiveOurImpactItem(null);
                    }}
                    className="flex justify-center cursor-pointer transition-colors duration-300"
                  >
                    <div className="w-full max-w-xs text-2xl font-bold text-gray-700 hover:text-blue-500 text-left">
                      {item.name}
                    </div>
                  </li>
                ))}
              </ul>
            </div>

            {/* Vertical Line */}
            <div className="w-px bg-gray-300"></div>

            {/* Second div with description */}
            <div className="w-1/2 p-6 flex flex-col items-center justify-start">
              {activeOurImpactItem && activeOurImpactItem.name === 'Feedback' ? (
                <div className="text-center w-full">
                  <p className="mt-2 text-lg mb-4">{activeOurImpactItem.description}</p>
                  <Carousel showThumbs={false} dynamicHeight={false} showIndicators={true}>
                    {activeOurImpactItem.videos.map((videoUrl, index) => (
                      <div key={index} className="video-container">
                        <iframe
                          width="100%"
                          height="400"
                          src={videoUrl}
                          title={`Feedback Video ${index + 1}`}
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                          allowFullScreen
                          style={{ margin: '0 auto' }}
                        ></iframe>
                      </div>
                    ))}
                  </Carousel>
                </div>
              ) : (
                <div className="text-lg text-gray-500 text-center">
                  <p className="font-bold text-2xl mb-4">Discover Our Impact</p>
                  <p className="mb-2">Hover over an item to see its description.</p>
                </div>
              )}
            </div>
          </div>
        )}


        <Partner open={open} handleClose={handleClose} />
      </nav>
      <ScrollToTopButton />
    </div>
  );
};

export default Navbar;