import React from 'react';
import bercvideo from '../assets/intro.mp4'; 

const Home = () => {
  return (
    <>
      {/* Full-Screen MP4 Video Section */}
      <section id="home" className="relative h-screen w-full flex items-center justify-center overflow-hidden">
        <video
          src={bercvideo} 
          autoPlay
          loop
          muted
          playsInline
          className="absolute top-0 left-0 w-full h-full object-cover"
        />

        {/* Overlay Text in Full Width */}
        <div className="absolute inset-0 flex items-center justify-center">
          <div className="p-8 bg-gray-700 bg-opacity-80 text-white w-full text-center">
            <h1 className="text-4xl md:text-5xl font-bold mb-4 text-start">
              Buildings Energy Research Center
            </h1>
            <p className="text-xl md:text-2xl text-start">
              A Pathway Towards Clean, Green & Sustainable Energy Buildings
            </p>
          </div>
        </div>
      </section>

      {/* Video Section */}
      <section id="video" className="w-full p-8 flex flex-col gap-10 bg-white items-center justify-center">
        <p className="mb-4 text-black font-semibold text-lg w-full max-w-4xl text-center">
          Our recent Training Bootcamp highlights, "Sustainability in Construction Sector" held from 1st September to 15th September at Bara Gali, 
          University of Peshawar Campus.
        </p>
        <div className="w-full max-w-4xl">
          <iframe 
            width="100%"
            height="500" 
            src="https://www.youtube.com/embed/JZ4kNELEfKg?si=wXvFD2QM7WsYPhIo" 
            title="YouTube video player" 
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
            allowFullScreen
          ></iframe>
        </div>
      </section>
    </>
  );
};

export default Home;
