import React, { useState } from 'react';
import { Modal, Box } from '@mui/material';
import baragali from '../assets/baragali.jpeg';
import serena from '../assets/serena.jpeg';
import ramada from '../assets/ramada.jpeg';
import baragali1 from '../assets/baragali.jpeg';
// training images
import baragali2 from '../assets/trainingImages/baragali2.jpg';
import baragali3 from '../assets/trainingImages/baragali3.jpg';
import baragali4 from '../assets/trainingImages/baragali4.jpg';
import baragali5 from '../assets/trainingImages/baragali5.jpg';
import baragali6 from '../assets/trainingImages/baragali6.jpg';
import baragali7 from '../assets/trainingImages/baragali7.jpg';
import baragali8 from '../assets/trainingImages/baragali8.jpg';
import baragali9 from '../assets/trainingImages/baragali9.jpg';
import baragali10 from '../assets/trainingImages/baragali10.jpg';
import baragali11 from '../assets/trainingImages/baragali11.jpg';
import baragali12 from '../assets/trainingImages/baragali12.jpg';
import baragali13 from '../assets/trainingImages/baragali13.jpg';
import baragali14 from '../assets/trainingImages/baragali14.jpg';
import baragali15 from '../assets/trainingImages/baragali15.jpg';
import baragali16 from '../assets/trainingImages/baragali16.jpg';
import baragali17 from '../assets/trainingImages/baragali17.jpg';
import bara1 from '../assets/trainingImages/bara1.jpg';
import bara2 from '../assets/trainingImages/bara2.jpg';
import bara3 from '../assets/trainingImages/bara3.jpg';
import bara4 from '../assets/trainingImages/bara4.jpg';
import bara5 from '../assets/trainingImages/bara5.jpg';
import bara6 from '../assets/trainingImages/bara6.jpg';
import bara7 from '../assets/trainingImages/bara7.jpg';
import bara8 from '../assets/trainingImages/bara8.jpg';
import bara9 from '../assets/trainingImages/bara9.jpg';
import bara10 from '../assets/trainingImages/bara10.jpg';
import bara11 from '../assets/trainingImages/bara11.jpg';
import bara12 from '../assets/trainingImages/bara12.jpg';
import bara13 from '../assets/trainingImages/bara13.jpg';
import bara14 from '../assets/trainingImages/bara14.jpg';
import bara15 from '../assets/trainingImages/bara15.jpg';
import ramada1 from '../assets/trainingImages/ramada1.jpg';
import ramada2 from '../assets/trainingImages/ramada2.jpg';
import ramada3 from '../assets/trainingImages/ramada3.jpg';
import ramada4 from '../assets/trainingImages/ramada4.jpg';
import ramada5 from '../assets/trainingImages/ramada5.jpg';
import ramada6 from '../assets/trainingImages/ramada6.jpg';
import ramada7 from '../assets/trainingImages/ramada7.jpg';
import ramada8 from '../assets/trainingImages/ramada8.jpg';
import ramada9 from '../assets/trainingImages/ramada9.jpg';
import ramada10 from '../assets/trainingImages/ramada10.jpg';

const NewsEvents = () => {
  const [openModal, setOpenModal] = useState(false);
  const [modalType, setModalType] = useState(''); // To track which modal is open
  const [currentImages, setCurrentImages] = useState([]); // To store images for training pictures

  const handleOpenModal = (type, images = []) => {
    setModalType(type); // Set the type of modal content
    setCurrentImages(images); // Set the images for training
    setOpenModal(true);
  };

  const handleCloseModal = () => setOpenModal(false);

  // Names list for attendees
  const attendees = [
    "PDA", "FDA", "Pakistan Green Building Council", "Local Government ADLG",
    "NEECA", "GIZ", "PEECA", "KPPHA", "CDA", "DHA", "ENERGY & POWER DEPTT",
    "NESPAK", "Communications & Works (Punjab)", "Galiyat Development Authority (GDA)",
    "Multan Development Authority (MDA)", "Institute of Architects, Pakistan (IAP)",
    "PUNJAB CENTRAL DISTRICT DEVELOPMENT AUTHORITY (PCBDDA)", "P & D (PESHAWAR)",
    "FEDERAL GOVT EMPLOYEES HOUSING AUTHORITY (FGEHA)", "KP Provincial Housing Authority",
    "LUMS", "Pakistan Council of Architects and Town Planners (PCATP)", "Communications & Works (KP)",
    "LOCAL GOVERNMENT (KPK)", "Lahore Development Authority (LDA)", "Planning & Development (Sindh)",
    "NEECA", "Provincial Housing Authority (Federal)", "Sindh Building Control Authority",
    "Punjab Energy Efficiency Conservation Authority", "NED", "PCATP"
  ];

  const images = [
    {
      src: baragali,
      alt: 'Baragali View',
      hoverText: 'IMPLEMENTATION TOOLS FOR ENERGY CONSERVATION BUILDING CODE 2023',
      trainingImages: [baragali2, baragali3, baragali4, baragali5, baragali6, baragali7, baragali8, baragali9, baragali10, baragali11, baragali12, baragali13, baragali14, baragali15, baragali16, baragali17]
    },
    {
      src: serena,
      alt: 'Serena Hotel',
      hoverText: 'FRAMEWORK FOR THE IMPLEMENTATION OF SUSTAINABILITY AND ENERGY EFFICIENCY IN BUILDINGS',
      trainingImages: []
    },
    {
      src: ramada,
      alt: 'Ramada Hotel',
      hoverText: 'TRANSFORMATION OF THE CONSTRUCTION SECTOR IN PAKISTAN',
      trainingImages: [ramada1, ramada2, ramada3, ramada4, ramada5, ramada6, ramada7, ramada8, ramada9, ramada10]
    },
    {
      src: baragali1,
      alt: 'Baragali Hills',
      hoverText: 'IMPLEMENTATION TOOLS FOR ENERGY CONSERVATION BUILDING CODE 2023',
      trainingImages: [bara1, bara2, bara3, bara4, bara5, bara6, bara7, bara8, bara9, bara10, bara11, bara12, bara13, bara14, bara15]
    },
  ];

  return (
    <>
      <section id="news-events" className="min-h-screen flex flex-col items-center justify-center p-8">
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-8">
          {images.map((image, index) => (
            <div
              key={index}
              className="relative group overflow-hidden"
              style={{ height: '400px', width: '100%' }}
            >
              <img
                src={image.src}
                alt={image.alt}
                className="w-full h-full object-cover transition-transform duration-500 transform group-hover:scale-110"
              />
              {/* Hover effect */}
              <div className="absolute inset-0 bg-black bg-opacity-50 opacity-0 group-hover:opacity-100 transition-opacity duration-500 flex flex-col items-center justify-center">
                <p className="text-white text-center p-4">{image.hoverText}</p>
                {/* Buttons side by side */}
                <div className="mt-4 flex space-x-4">
                  {/* Training Pictures Button */}
                  {image.trainingImages.length > 0 ? (
                    <button
                      className="bg-blue-500 text-white py-2 px-4 rounded-lg transition-all duration-300 hover:bg-blue-600"
                      onClick={() => handleOpenModal('training', image.trainingImages)}
                    >
                      Training Pictures
                    </button>
                  ) : (
                    <button
                      className="bg-gray-400 text-white py-2 px-4 rounded-lg cursor-not-allowed"
                      disabled
                    >
                      Training Pictures
                    </button>
                  )}
                  {/* Attendees Button */}
                  <button
                    className="bg-green-500 text-white py-2 px-4 rounded-lg transition-all duration-300 hover:bg-green-600"
                    onClick={() => handleOpenModal('attendees')}
                  >
                    Attendees
                  </button>
                </div>
              </div>
            </div>
          ))}
        </div>
      </section>

      {/* Modal */}
      <Modal open={openModal} onClose={handleCloseModal}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: modalType === 'attendees' ? '40%' : '70%', // Adjust modal width
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
            borderRadius: '8px',
            maxHeight: '80vh',
            overflowY: 'auto',
            textAlign: 'center'
          }}
        >
          <h2 className="text-2xl font-bold mb-4">
            {modalType === 'training' ? 'Training Pictures' : 'Attendees'}
          </h2>
          {/* Conditional rendering based on modalType */}
          {modalType === 'training' ? (
            currentImages.length > 0 ? (
              <div className="grid grid-cols-2 gap-4">
                {currentImages.map((src, idx) => (
                  <img key={idx} src={src} alt={`Training ${idx + 1}`} className="w-full h-auto object-cover" />
                ))}
              </div>
            ) : (
              <p>No training images available.</p>
            )
          ) : (
            <div className="text-left">
              {attendees.map((attendee, index) => (
                <p key={index} className="text-gray-700 mb-2">
                  {attendee}
                </p>
              ))}
            </div>
          )}
          <button
            className="mt-4 bg-red-500 text-white py-2 px-4 rounded-lg transition-all duration-300 hover:bg-red-600 mx-auto"
            onClick={handleCloseModal}
            style={{ display: 'block' }} // Center close button
          >
            Close
          </button>
        </Box>
      </Modal>
    </>
  );
};

export default NewsEvents;
