import React from 'react';
import { Container, Grid, Box, Typography, Link, TextField, Button } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faLinkedin, faYoutube } from '@fortawesome/free-brands-svg-icons';
import logo from '../assets/berc-wh.png';
import { faMapMarkerAlt, faEnvelope, faPhone } from '@fortawesome/free-solid-svg-icons';

const FooterText = () => {
  return (
    <Box sx={{ backgroundColor: '#026EB5', color: 'white', py: 6 }}>
      <Container maxWidth="lg">
        <Grid container spacing={4} alignItems="center">
          {/* Logo Section */}
          <Grid item xs={12} sm={6}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <img src={logo} alt="BERC Logo" style={{ height: '100px', marginRight: '20px' }} />
            </Box>
          </Grid>

          {/* Newsletter Sign-up Section */}
          <Grid item xs={12} sm={6}>
            <Typography variant="h6" gutterBottom style={{ fontWeight: 'bold', textAlign: 'center' }}>
              Subscribe for Updates
            </Typography>
            <Box component="form" sx={{ display: 'flex', gap: 1, justifyContent: 'center', mt: 2 }}>
              <TextField
                variant="outlined"
                size="small"
                placeholder="Enter your email"
                sx={{ backgroundColor: 'white', borderRadius: 1, width: '70%' }}
              />
              <Button variant="contained" sx={{ backgroundColor: '#fff', color: '#026EB5', '&:hover': { backgroundColor: '#026EB5', color: '#fff' } }}>
                Subscribe
              </Button>
            </Box>
          </Grid>
        </Grid>

        {/* Horizontal Line */}
        <Box sx={{ mt: 4 }}>
          <hr style={{ borderColor: 'white', marginBottom: '16px' }} />
        </Box>

        {/* Google Map and Contact Info Section */}
        <Grid container spacing={4} alignItems="center" sx={{ mt: 4 }}>
          <Grid item xs={12} md={6}>
            <Box display="flex" alignItems="center" mb={2}>
              <Typography variant="h6" component="h2" style={{ fontSize: '25px', color: '#fff', fontWeight: 'bold' }}>
                Contact Information:
              </Typography>
            </Box>
            <Box display="flex" alignItems="center" mb={2} mt={4}>
              <FontAwesomeIcon icon={faMapMarkerAlt} style={{ color: '#fff', fontSize: '25px', marginRight: '1rem' }} />
              <Typography variant="body1" style={{ fontSize: '16px', fontWeight: 300 }}>
                CISNR New Academic Block, Basement, UET, Peshawar, Khyber Pakhtunkhwa 25125
              </Typography>
            </Box>
            <Box display="flex" alignItems="center" mb={2} mt={4}>
              <FontAwesomeIcon icon={faEnvelope} style={{ color: '#fff', fontSize: '25px', marginRight: '1rem' }} />
              <Typography variant="body1" component="h2" style={{ fontSize: '16px', color: '#fff' }}>
                Email: info@berc.pk
              </Typography>
            </Box>

            <Box display="flex" alignItems="center" mb={2} mt={4}>
              <FontAwesomeIcon icon={faPhone} style={{ color: '#fff', fontSize: '25px', marginRight: '1rem' }} />
              <Typography variant="body1" component="h2" style={{ fontSize: '16px', color: '#fff' }}>
                Phone: +92-91-9222104
              </Typography>
            </Box>
          </Grid>

          <Grid item xs={12} md={6}>
            <Box sx={{ height: '100%', borderRadius: 2, boxShadow: 3, overflow: 'hidden' }}>
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3307.576141560076!2d71.48336697515295!3d34.00169397941384!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x0!2zMzTCsDAwJzA2LjEiTiA3McKwMjknMDguMCJF!5e0!3m2!1sen!2s!4v1691049437619!5m2!1sen!2s"
                width="100%"
                height="300"
                allowFullScreen=""
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
                title="Google Maps"
              />
            </Box>
          </Grid>
        </Grid>

        {/* Text Section beneath the logo, subscribe */}
        <Box sx={{ mt: 4 }}>
          <Grid container spacing={4}>
            <Grid item xs={12} sm={6}>
              <Typography variant="h6" className="text-2xl text-white" sx={{ fontWeight: 'bold' }}>
                Buildings Energy Research Center
              </Typography>
              <Typography variant="body1" className="text-lg text-white" sx={{ fontWeight: '300' }}>
              A Pathway Towards Clean, Green & Sustainable Energy Buildings
              </Typography>
            </Grid>



            {/* Follow Us Section */}
            <Grid item xs={12} sm={6} sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
              <Box sx={{ textAlign: 'right' }}>
                <Typography variant="h6" style={{ fontWeight: 'bold', textAlign: 'right', color: 'white' }}>
                  Follow Us
                </Typography>
                <Box sx={{ display: 'flex', gap: 2, justifyContent: 'flex-end' }}>
                  <Link href="https://www.facebook.com/people/Buildings-Energy-Research-Center/61557251373408/" color="inherit" sx={{ display: 'flex', alignItems: 'center' }}>
                    <FontAwesomeIcon icon={faFacebook} size="lg" />
                  </Link>
                  <Link href="https://www.linkedin.com/company/buildings-energy-research-center/" color="inherit" sx={{ display: 'flex', alignItems: 'center' }}>
                    <FontAwesomeIcon icon={faLinkedin} size="lg" />
                  </Link>
                  <Link href="https://www.youtube.com/" color="inherit" sx={{ display: 'flex', alignItems: 'center' }}>
                    <FontAwesomeIcon icon={faYoutube} size="lg" />
                  </Link>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>

        {/* Footer Bottom Text */}
        <Box sx={{ textAlign: 'center', mt: 4 }}>
          <Typography variant="body2">&copy; {new Date().getFullYear()} BERC. All rights reserved.</Typography>
        </Box>
      </Container>
    </Box>
  );
};

export default FooterText;
