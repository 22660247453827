import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCertificate,
  faComments,
  faFlask,
  faRecycle,
  faArrowRight,
  faBuilding,
} from '@fortawesome/free-solid-svg-icons';
import { motion, useAnimation } from 'framer-motion';
import { useInView } from 'framer-motion';

const Services = () => {
  const controls = useAnimation();
  const ref = React.useRef(null);
  const inView = useInView(ref, { once: true, threshold: 0.1 });

  React.useEffect(() => {
    if (inView) {
      controls.start('visible');
    }
  }, [controls, inView]);

  const cardVariants = {
    hidden: { opacity: 0, y: 50 },
    visible: (i) => ({
      opacity: 1,
      y: 0,
      transition: {
        delay: i * 0.15,
        duration: 0.6,
        ease: 'easeOut',
      },
    }),
    hover: { scale: 1.05 },
    tap: { scale: 0.95 },
  };

  return (
    <>
      <section
        id="services"
        className="min-h-screen flex flex-col items-center justify-center p-8 text-xl bg-[#E0FFC7]"
      >
        {/* Container to align <p> and cards */}
        <div className="w-full max-w-5xl">
          <h1 className="text-center font-medium text-2xl md:text-4xl mt-10 md:mt-0">
            BERC Services
          </h1>
          <p className="text-black text-center mb-12 font-light mt-4">
            BERC offers a comprehensive suite of services tailored to your unique needs. From consultation to implementation, our dedicated team strives for excellence at every step, contributing to the creation of energy-efficient, sustainable, and resilient buildings nationwide.
          </p>

          <motion.div
            ref={ref}
            animate={controls}
            initial="hidden"
            className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 w-full"
          >
            {[
              {
                title: 'Advisory Services',
                description: 'Expert guidance to achieve certifications like ISO 50001 and EDGE.',
                icon: faCertificate,
                color: '#000',
                buttonText: 'Contact Us',
              },
              {
                title: 'Engineering Consulting',
                description: 'Professional consultation for new and existing building projects.',
                icon: faComments,
                color: '#000',
                buttonText: 'Request Free Consultation',
              },
              {
                title: 'Energy Modeling',
                description: 'Energy modeling, simulation, and auditing services for buildings.',
                icon: faBuilding,
                color: '#000',
                buttonText: 'Request Demo',
              },
              {
                title: 'Research & Development',
                description: 'Innovative research and development for building efficiency.',
                icon: faFlask,
                color: '#000',
              },
              {
                title: 'Policy & Advocacy',
                description: 'Policy research and workshops to engage policymakers.',
                icon: faRecycle,
                color: '#000',
              },
              {
                title: 'Capacity Development',
                description: 'Training programs and webinars on energy-efficient buildings.',
                icon: faRecycle,
                color: '#000',
              },
            ].map((service, index) => (
              <motion.div
                key={index}
                className="bg-[#ffffff] shadow-md p-6 text-center rounded-lg flex flex-col h-85 relative"
                custom={index}
                variants={cardVariants}
                whileHover="hover"
                whileTap="tap"
              >
                {/* Icon at top left */}
                <FontAwesomeIcon
                  icon={service.icon}
                  className="absolute top-4 left-4"
                  style={{ fontSize: '20px', color: service.color }}
                />
                {/* Arrow at top right */}
                <FontAwesomeIcon
                  icon={faArrowRight}
                  className="absolute top-4 right-4 rotate-45"
                  style={{ fontSize: '20px', color: service.color }}
                />
                <h2 className="text-3xl font-semibold text-black mb-5 mt-8">{service.title}</h2>
                <p className="text-black text-lg mb-4 font-light">{service.description}</p>
                <div className="mt-auto w-full">
                  <button className="w-full bg-blue-600 text-white font-semibold py-2 rounded-lg text-sm transition-all duration-300 hover:bg-blue-700 flex items-center justify-center group">
                    {service.buttonText}
                  </button>
                </div>
              </motion.div>
            ))}
          </motion.div>
        </div>
      </section>
    </>
  );
};

export default Services;
