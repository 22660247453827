import React from 'react';
import { Modal, Box, Button, Stack } from '@mui/material';
import webinar from '../../assets/Product.png'; 

const WebinarModal = ({ isOpen, onClose }) => {
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '80%', // Increased width
    height: '85%', // Increased height
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    background: 'transparent', // Removed background color
    border: 'none', // Removed border
    boxShadow: 'none', // Removed shadow
    outline: 'none', // Removed outline
  };

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      aria-labelledby="upcoming-webinar-title"
      aria-describedby="upcoming-webinar-description"
      sx={{ backdropFilter: 'blur(3px)' }} // Optional: Adds a subtle blur effect to the background
    >
      <Box sx={style}>
        <img src={webinar} alt="Upcoming Webinar" style={{ maxWidth: '100%', maxHeight: '100%' }} />
        
        {/* Stack component to align buttons side by side */}
        <Stack direction="row" spacing={2} sx={{ mt: 3 }}>
        

        
          <Button 
            variant="contained" 
            color="primary" 
            onClick={onClose}
          >
            Close
          </Button>
        </Stack>
      </Box>
    </Modal>
  );
};

export default WebinarModal;
