import React, { useState } from 'react';
import Avatar from '@mui/material/Avatar';
import Modal from '../components/modal/modal';
import teamMembers from '../components/data/TeamMembers';
import LinkedInIcon from '@mui/icons-material/LinkedIn';

const Team = () => {
  const [open, setOpen] = useState(false);
  const [selectedMember, setSelectedMember] = useState({});

  const handleClickOpen = (member) => {
    setSelectedMember(member);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>

      <header className="w-full py-2" style={{ backgroundColor: '#ffff' }}>
        <h1 className="text-center font-medium text-2xl md:text-4xl mt-10 md:mt-0">
          Meet our Team
        </h1>
      </header>

      <section id="team-members" className="flex flex-col items-center justify-center p-10" style={{ backgroundColor: '#fff' }}>
        <div className="flex flex-wrap justify-center">
          {teamMembers.map((member, index) => (
  <div key={index} className="w-full sm:w-1/2 md:w-1/3 lg:w-1/4 p-4 flex justify-center">
    <div 
      className="bg-white rounded-lg shadow-md p-6 flex flex-col items-center text-center cursor-pointer transform transition duration-500 hover:scale-105 relative group" 
      style={{ width: '250px', height: '350px' }} // Increased height to accommodate larger image
      onClick={() => handleClickOpen(member)}
    >
      <Avatar 
        src={member.img} 
        alt={member.name} 
        sx={{ width: 128, height: 128, mb: 4 }} // Increased size of the avatar
      />
      <div>
        <h3 className="text-xl font-semibold mb-2 text-center">{member.name}</h3>
        <p className="text-gray-600">{member.role}</p>
      </div>
      <div className="absolute bottom-0 left-0 w-full text-center pb-2 hidden group-hover:block">
        <span className="bg-[rgb(232,255,174)] text-sm px-2 py-1 rounded">View</span>
      </div>
      {/* LinkedIn Icon Link */}
      {member.linkedin && (
        <a 
          href={member.linkedin} 
          target="_blank" 
          rel="noopener noreferrer" 
          className="mt-2"
        >
          <LinkedInIcon style={{ color: '#0077b5' }} fontSize="large" />
        </a>
      )}
    </div>
  </div>
))}


        </div>
      </section>

      <Modal
        open={open}
        handleClose={handleClose}
        title={selectedMember.name}
        description={selectedMember.description}
        img={selectedMember.img}
      />
    </div>
  );
};

export default Team;
