import React, { useState, useEffect } from 'react';
import bercIntro from './assets/bercIntro.gif';

import Home from './pages/Home';
import Team from './pages/Team';
import Navbar from './pages/Navbar';
import Services from './pages/Services';
import Gallery from './pages/Gallery';
import FooterText from './components/FooterText';
import NewsEvents from './pages/NewsEvents';
// import WebinarModal from './components/modal/WebinarModal';
import SubscriberModal from './components/modal/SubscriberModal';

function App() {
  const [isLoading, setIsLoading] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 1500);

    return () => clearTimeout(timer);
  }, []);

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div className="App">
      {isLoading ? (
        <div className="flex items-center justify-center min-h-screen bg-[#fff]">
          <img
            src={bercIntro}
            alt="Loading..."
            className="w-auto h-auto max-w-full max-h-full object-contain"
          />
        </div>
      ) : (
        <div>
          <Navbar />
          <Home />
          {/* <WhyUs /> */}
          <Services />
          <NewsEvents />
          <Team />
          <Gallery />
          <FooterText />
          {/* <WebinarModal isOpen={isModalOpen} onClose={handleCloseModal} /> */}
          <SubscriberModal isOpen={isModalOpen} onClose={handleCloseModal} />
        </div>
      )}
    </div>
  );
}

export default App;
